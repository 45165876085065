import * as React from "react";
import { graphql } from "gatsby";
import { IGuestCheckoutProps } from "../pageHelpers/GuestCheckout/GuestCheckoutProps";
import Layout from "../components/global/Layout/Layout";
import { useState, useEffect } from "react";
import queryString from "query-string";
import IGuestCheckout from "../@types/IGuestCheckout";
import { guestCheckout } from "../services/crm";
import { Col, Container, Row } from "react-bootstrap";
import { GuestForm } from "../pageHelpers/GuestPropertyView/GuestPropertyViewHelpers";

const GuestCheckout: React.FC<IGuestCheckoutProps> = (props) => {
  const _site = props.data.allRlhsite.edges[0].node;
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean | null>(null);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  useEffect(() => {
    const guestObject: IGuestCheckout = {};

    const location = typeof window !== "undefined" ? window.location : "";
    // Check for query parameters.
    const queryParams = location.search.length
      ? queryString.parse(location.search.toString())
      : null;
    if (queryParams !== null) {
      if (queryParams.ReservationID && queryParams.ReservationID.length) {
        guestObject.ReservationID = queryParams.ReservationID.toString().trim();
      }
      if (queryParams.EmailID && queryParams.EmailID.length) {
        guestObject.EmailID = queryParams.EmailID.toString().trim();
      }
      if (queryParams.CheckoutDate && queryParams.CheckoutDate.length) {
        guestObject.CheckoutDate = queryParams.CheckoutDate.toString().trim();
      }
      if (queryParams.PropertyCode && queryParams.PropertyCode.length) {
        guestObject.PropertyCode = queryParams.PropertyCode.toString().trim();
      }
    }
    setIsValid(Object.keys(guestObject).length === 4);
  });

  const handleSubmit = async (values: any) => {
    setIsSubmiting(true);
    const response = await guestCheckout(values);
    setIsSubmiting(false);
    setIsSubmitted(response);
  };

  return (
    <Layout {...{ site: _site }}>
      <Container>
        <Row>
          <Col>
            <GuestForm
              isValid={isValid as boolean}
              submitted={isSubmitted as boolean}
              submitting={isSubmiting}
              handleSubmit={handleSubmit}
            ></GuestForm>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const pageQuery = graphql`
  query GuestCheckoutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          machine_name
          relationships {
            paragraphs: field_sections {
              type: __typename
              ...ParagraphPageBanner
              ...ParagraphPageIntro
              ...ParagraphFeaturedDestinations
              ...ParagraphMediaCta
            }
          }
        }
      }
    }
  }
`;
export default GuestCheckout;
